import axios from '@axios'

export default {
  namespaced: true,
  // defaultView: 'list',
  state: {
    calendarOptions: [
      {
        color: 'info',
        label: 'Rapat',
      },
      {
        color: 'warning',
        label: 'Lain',
      },
      // {
      //   color: 'warning',
      //   label: 'Family',
      // },
      // {
      //   color: 'success',
      //   label: 'Holiday',
      // },
      // {
      //   color: 'info',
      //   label: 'ETC',
      // },
    ],
    selectedCalendars: ['Rapat', 'Lain'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/siap/schedule/list', {
            params: {
              calendars: calendars.join(','),
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      const {
        attendance,
        title,
        description,
        start,
        end,
        location,
        pic,
        category,
      } = event

      const mappedAttendance = attendance.map(a => ({ user_id: a.userID, type: 'Member' }))

      mappedAttendance.push({
        user_id: pic.userID,
        type: 'PIC',
      })

      return new Promise((resolve, reject) => {
        axios
          .post('/siap/schedule/create', {
            attendance: mappedAttendance,
            title,
            description,
            start,
            end,
            location,
            category,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      const {
        id,
        attendance,
        title,
        description,
        start,
        end,
        location,
        pic,
        category,
      } = event

      const mappedAttendance = attendance.map(a => ({ user_id: a.userID, type: 'Member' }))

      mappedAttendance.push({
        user_id: pic.userID,
        type: 'PIC',
      })

      return new Promise((resolve, reject) => {
        axios
          .post('/siap/schedule/update', {
            id: Number(id),
            attendance: mappedAttendance,
            title,
            description,
            start,
            end,
            location,
            category,
          })
          .then(() => resolve({ id, start, end }))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
