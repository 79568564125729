<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        Tambah Agenda
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="modal = !modal"
      >
        <feather-icon
          icon="SendIcon"
          class="mr-50"
        />
        Bagikan
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars</span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          Tampilkan semua
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />

    <!-- modal -->
    <b-modal
      id="modal-primary"
      v-model="modal"
      ok-only
      ok-title="Kembali"
      modal-class="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Kembali"
      centered
      size="lg"
      title="Bagikan Agenda"
      no-stacking
      @ok="modal = !modal"
    >
      <b-row class="match-height">
        <b-col md="12">
          <date-range-picker
            ref="filter"
            v-model="filterDate"
            :opens="'left'"
            class="mr-1"
            :ranges="ranges()"
            :locale-data="optionDate"
            @update="getAgenda()"
          >
            <template
              v-slot:input="picker"
              style="min-width: 350px;width: max-content;"
            >
              <!-- {{ picker.startDate | date }} - {{ picker.endDate | date }} -->
              {{ dateFormat(picker.startDate ) }} - {{ dateFormat(picker.endDate ) }}
            </template>
          </date-range-picker>
        </b-col>
      </b-row>

      <b-row
        class="match-height"
        style="max-height: 200px;overflow-y: scroll;"
      >
        <b-col
          v-for="(item, index) in event"
          :key="index"
          md="12"
          style="border-bottom: solid 1px gainsboro;margin: 10px 0;padding: 0px 15px 10px;"
        >
          <div style="margin-bottom: 5px;">
            <b> {{ item.title }} </b>
          </div>
          <div class="mb-1">
            {{ dateFormat(item.start) }} - {{ dateFormat(item.end) }}
          </div>
          <ShareNetwork
            network="whatsapp"
            url=""
            title="Agenda KAN-Jabung"
            :description="`Tanggal: ${dateFormat(item.start)} - ${dateFormat(item.end)}, Event: ${item.title} `"
          >
            Bagikan
          </ShareNetwork>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Vue from 'vue'
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  VBModal,
  BFormDatepicker,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import dayjs from 'dayjs'
import VueSocialSharing from 'vue-social-sharing'
import DateRangePicker from 'vue2-daterange-picker'
import useCalendarSidebar from './useCalendarSidebar'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueSocialSharing)

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    VBModal,
    BFormDatepicker,
    BRow,
    BCol,
    DateRangePicker,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      modal: false,
      startDate: null,
      endDate: null,
      event: [],
      event2: [],
      filterDate: {
        startDate: null,
        endDate: null,
      },
      optionDate: {
        firstDay: 1,
        format: 'dd-mm-yyyy',
        cancelLabel: 'Tutup',
        applyLabel: 'Terapkan',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
    }
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },

  methods: {
    async getAgenda() {
      const { data } = await axios.get('/siap/schedule/list', {
        params: {
          start: dayjs(this.filterDate.startDate).format('YYYY-MM-DD 00:00:00'),
          end: dayjs(this.filterDate.endDate).format('YYYY-MM-DD 23:59:59'),
        },
      })
      this.event = data
      this.event2 = data.map(e => ({ event: e.title }))
    },
    ranges() {
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0)

      const yesterday = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .subtract(1, 'day')

      const thisWeekStart = dayjs().startOf('week')
      const thisWeekEnd = dayjs().endOf('week')

      const lastWeekStart = thisWeekStart.subtract(1, 'week')
      const lastWeekEnd = lastWeekStart.endOf('week')

      const thisMonthStart = dayjs().startOf('month')
      const thisMonthEnd = dayjs().endOf('month')

      const lastMonthStart = thisMonthStart.subtract(1, 'month')
      const lastMonthEnd = lastMonthStart.endOf('month')

      const thisYearStart = dayjs().startOf('year')
      const thisYearEnd = dayjs().endOf('year')
      return {
        'Hari ini': [today.toDate(), today.toDate()],
        Kemarin: [yesterday.toDate(), yesterday.toDate()],
        'Minggu ini': [thisWeekStart.toDate(), thisWeekEnd.toDate()],
        'Minggu Kemarin': [lastWeekStart.toDate(), lastWeekEnd.toDate()],
        'Bulan ini': [thisMonthStart.toDate(), thisMonthEnd.toDate()],
        'Bulan Kemarin': [lastMonthStart.toDate(), lastMonthEnd.toDate()],
        'Tahun ini': [thisYearStart.toDate(), thisYearEnd.toDate()],
      }
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
  },
}
</script>

<style>
</style>
